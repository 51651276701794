<template>
  <div class="orderList">
    <div class="orderList_search">
      <div class="select-container">
        <select v-model="selectType" @change="onSelectTypeChange">
          <!--          <option value="1">按提货码</option>-->
          <option value="2">按手机号</option>
        </select>
      </div>
      <search-v1
          class="orderList_search_item"
          v-model="keyword"
          @search="search"
          @clear="search"
          :placeholder="placeholderText"
      ></search-v1>
    </div>
    <div v-if="goods_number>0" class="goods-list-tip">商品总份数:{{ goods_number }}件</div>
    <div class="orderList_body">
      <scrollview @onRefresh="onRefresh" @onReload="onReload" :height="scrollHeight">
        <template v-if="list.length > 0">
          <div class="card" v-for="(item, index) in list" :key="index">
            <div class="card-line box-m-t-20">
              <cell-v3 :hide-icon="true">
                <div class="main-title" slot="title">
                  <div style="font-size: 14px;font-weight: 300;" v-if="item.flow_up_code">
                    <template v-if="orderTypes==1">送货码：</template>
                    <template v-if="orderTypes==2">提货码：</template>
                    {{ item.flow_up_code }}
                  </div>
                  <div style="font-size: 14px;font-weight: 300;" v-else>{{ item.order_sn }}</div>
                </div>
                <template>
                  <div class="order-status" :style="{ color: statusColor }">
                    {{ orderStatus }}
                  </div>
                </template>
              </cell-v3>
            </div>
            <div class="card-line">
              <cell-v3 :hide-icon="true">
                <div class="title" slot="title">到件时间:</div>
                <div class="values">{{ item.site_point_receive_time }}</div>
              </cell-v3>
            </div>
            <div class="card-line">
              <cell-v3 :hide-icon="true">
                <div class="title" slot="title">联 系 人 ：</div>
                <div class="values">{{ item.real_name }}</div>
              </cell-v3>
            </div>
            <div class="card-line" @click="contact(item.real_mobile)">
              <cell-v3 :hide-icon="true">
                <div class="title" slot="title">联系电话：</div>
                <div class="values">{{ item.real_mobile }}</div>
              </cell-v3>
            </div>
            <div class="card-line">
              <cell-v3 :hide-icon="true">
                <div class="title" slot="title">订单备注：</div>
                <div class="values">{{ item.remark }}</div>
              </cell-v3>
            </div>
            <div class="card_line" v-if="selectType == 2 && keyword">
              <button class="quick-button" @click="quickScan(item)">快速核销</button>
            </div>
            <div class="card-line box-m-t-20">
              <cell-v3 :hide-icon="true">
                <div class="scan-button" @click="showDetail(index)">
                  订单详情
                  <img v-if="hide" :src="require('@/static/image/icons/arrowUp.png')"/>
                  <img v-else :src="require('@/static/image/icons/arrowDown.png')"/>
                </div>
              </cell-v3>
            </div>

            <div class="goods-list" v-show="!(hide && index == hideIndex)">
              <div class="goods" v-for="(goodItem, goodIndex) in item.goodList" :key="goodIndex"
              >
                <goods-v4 :img-url="goodItem.cover" :statusText="goodItem.status_text"
                          :number="goodItem.total" :title="goodItem.title"/>
              </div>
            </div>
          </div>
        </template>
      </scrollview>
    </div>
  </div>
</template>

<script>
import CellV3 from '../../template/community/cell/cellV3'
import GoodsV4 from '../../template/community/goods/GoodsV4'
import SearchV1 from '../../template/community/search/searchV1'

export default {
  name: 'order-list',
  components: {SearchV1, GoodsV4, CellV3},
  data() {
    return {
      selectType: 2,
      placeholderText: '请输入客户提货码',
      hide: false,
      hideIndex: -1,
      //这里只是测试的配置，根据具体的业务设置具体的情况
      orderTypeStatus: {
        1: '配送中',
        2: '等待客户领取',
        3: '逾期未领取',
      },
      orderStatusColor: {
        1: '#3D74FF',
        2: '#1C741D',
        3: '#1C741D',
      },
      orderStatus: '待提货',
      statusColor: '#1C741D',
      orderTypes: 1,
      keyword: '',
      mobileKeyword: '',
      params: {
        page: 1,
        page_size: 10,
      },
      list: [],
      scrollHeight: 0,
      goods_number: 0,
    }
  },
  mounted() {

    let pageTitle = ''
    // document.title = '订单列表'
    let orderType = 1
    if (typeof this.$route.query.status != 'undefined') {
      orderType = parseInt(this.$route.query.status)
    }
    /**
     * 1 是待提货  2是已完成  3是已逾期  4是发送中
     */
    if (![1, 2, 3, 4].includes(orderType)) {
      orderType = 1
    }

    if (orderType == 1) {
      pageTitle = '配送中订单'
    } else if (orderType == 2) {
      pageTitle = '待客户领取订单'
    } else if (orderType == 3) {
      pageTitle = '已逾期订单'
    } else if (orderType == 4) {
      pageTitle = '即将发送到自提点的订单'
    }

    document.title = pageTitle

    this.orderStatus = this.orderTypeStatus[orderType]
    this.statusColor = this.orderStatusColor[orderType]
    this.orderTypes = orderType

    this.scrollHeight = window.innerHeight - document.querySelector('.orderList_search_item').clientHeight
    let mobileKeyword = this.$route?.query?.mobileKeyword || ''

    let selectType = 2
    if (mobileKeyword) {
      selectType = 2
      this.keyword = mobileKeyword
    }
    this.placeholderText = '请输入客户手机号码'
    this.selectType = selectType

    this.getOrderList()
  },
  methods: {
    onSelectTypeChange(e) {
      if (this.selectType == 1) {
        this.placeholderText = '请输入客户提货码'
      } else {
        this.placeholderText = '请输入客户手机号码'
      }
      this.keyword = ''
    },
    quickScan(item) {
      this.$dialog({
        title: "信息确认",
        content: "请您再次确认需要通过手机号搜索，核销收货人：" + item.real_name + '，收货人手机号码为：' + item.real_mobile + ' 的订单',
        onOkBtn() {  //确定按钮点击事件
          console.log('quickScan item', item)
          let params = {
            code: item.pick_up_code
          }
          let query = encodeURIComponent(
              JSON.stringify(params)
          )
          const url = '/web/selfExtract/scan-user-result?query=' + query
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: url,
          })
          this.close(); //关闭对话框
        },
      });
    },
    showDetail(keyhideIndex) {
      if (this.hideIndex == keyhideIndex) {
        //当前位置点击切换
        this.hide = !this.hide
        if (this.hide) {
          this.hideIndex = keyhideIndex
        } else {
          this.hideIndex = -1
        }
      } else {
        //切换位置后   先恢复位置 再重新设置位置
        this.hideIndex = -1
        this.hide = true
        this.hideIndex = keyhideIndex
      }
    },
    onRefresh(done) {
      this.list = []
      this.params.page = 1
      this.getOrderList().finally(() => {
        done()
      })
    },
    /**
     * step 当前加载结束
     * over 没有更多数据了
     */
    onReload(step, over) {
      console.log('上拉加载')
      this.params.page += 1
      this.getOrderList().finally(() => {
        if (this.list.length < this.params.page * this.params.page_size) {
          over()
        } else {
          step()
        }
      })
    },
    async getOrderList() {

      let params = {
        ...this.params,
        keyword: this.keyword,
        keywordType: this.selectType
      }


      try {
        let query = null
        // console.log('this.orderTypes',this.orderTypes)
        if (this.orderTypes == 1) {
          //待提货  再路上
          query = await this.$api.community.points.pointOnWay(params)
        } else if (this.orderTypes == 2) {
          //未完成
          query = await this.$api.community.points.pointUnFinishOrder(params)
        } else if (this.orderTypes == 3) {
          //已经逾期
          query = await this.$api.community.points.pointOverOrder(params)
        } else if (this.orderTypes == 4) {
          query = await this.$api.community.points.pointWillSendOrder(params)
        }
        if (query.data.list.length == 0) {
          return
        }
        this.list = [...this.list, ...query.data.list]
        console.log('this.list', this.list)
        this.goods_number = query.data.goods_number
      } catch (error) {
        //
      }
    },
    search() {
      this.params.page = 1
      this.list = []
      this.getOrderList()
    },
    contact(mobile) {
      self.$platform.wxsdk.wxRoute({
        type: 'navigateTo',
        url:
            '/web/makePhoneCall/common?mobile=' + mobile,
      })
    }
  },
}
</script>

<style scoped lang="scss">

.quick-button {
  margin: 0 auto;
  margin-top: 1px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 40px;
  background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
  background-clip: padding-box;
  border-radius: 29px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}


@include b(orderList) {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  @include e(search) {
    flex-shrink: 0;
    flex: 1;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 0 15px;

    @include e(item) {
      width: 345px;
      height: 32px;
    }

    .select-container {
      margin-right: 10px;
      display: flex;
      align-items: center;

      select {
        height: 30px;
        line-height: 30px;
        background: #f6f6f6;
        border: #f1f1f1 solid 1px;
        font-size: 14px;
        outline: none;
      }
    }
  }
  @include e(body) {
    box-sizing: border-box;
    flex-grow: 1;
  }
}

.goods-list-tip {
  width: 345px;
  background: #fff;
  margin: 0px auto;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px 22px 7px 19px;
  border-radius: 5px;
  font-size: 16px;
  color: #f46a17;
}

.card {
  width: 345px;
  background: #fff;
  margin: 0px auto;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 20px 22px 17px 19px;
  border-radius: 10px;

  .main-title {
    font-size: 16px;
    color: #333;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }

  .order-status {
    font-family: PingFangSC-Regular, PingFang SC;
    color: #1c741d;
    font-size: 14px;
    font-weight: 400;
  }

  .title,
  .values {
    color: #333333;
    font-size: 14px;
  }

  .card-line {
    margin-bottom: 15px;

    .scan-button {
      color: #f46a17;
      font-size: 14px;

      img {
        width: 14px;
        height: 14px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      color: #f46a17;

      img {
        margin-right: 5px;
        width: 14px;
        height: 14px;
      }
    }
  }

  .bottom-border {
    padding: 0 0 15px 0;
    border-bottom: #ebebeb solid 1px;
    margin-bottom: 20px;
  }

  .goods-list {
    width: 100%;
    border-top: #ebebeb solid 1px;
    padding-bottom: 15px;
    margin-top: 15px;

    .goods {
      margin-top: 15px;
    }
  }

  .money {
    margin-top: 15px;
    font-size: 16px;
    color: #c10000;
    font-weight: bold;
  }
}
</style>
